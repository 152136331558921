import * as React from 'react';
import Contacts from '../components/Contacts';
import Footer from '../components/Footer';
import Gallery from '../components/Gallery';
import Hero from '../components/Hero';
import Navigation from '../components/Navigation/Navigation';
import OpeningHours from '../components/OpeningHours';
import Prices from '../components/Prices';
import SEO from '../components/SEO';
import Styles from '../components/Styles';
import TopBar from '../components/TopBar/TopBar';
import { MobileMenuProvider } from '../context/MobileMenuContext';

const IndexPage = () => {
  return (
    <MobileMenuProvider>
      <SEO title="Home"/>
      <Navigation/>
      <TopBar/>
      <Hero/>
      <Styles/>
      <OpeningHours/>
      <Prices/>
      <Gallery/>
      <Contacts/>
      <Footer/>
    </MobileMenuProvider>
  )
}
export default IndexPage
