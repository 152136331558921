import { motion } from "framer-motion";
import React from "react";

const Gallery = () => {
  return (
    <div id="gallery"
         className="flex flex-col justify-center items-center min-h-screen bg-black overflow-hidden">
      <motion.div
        className="elfsight-app-d15106d2-06ee-46c8-8297-cb99a09c9686 py-2"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.8 }}
        variants={{
          hidden: {
            opacity: 0,
          },
          visible: {
            opacity: 1,
            transition: {
              duration: 1,
            }
          }
        }}
      ></motion.div>
    </div>
  )
}
export default Gallery;