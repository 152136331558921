import React, { createContext, ReactNode, useState } from 'react';

type MobileMenuContextType = {
  isOpen: boolean,
  toggle: () => void,
  hasOrderButton: boolean,
  showOrderButton: (value: boolean) => void,
}

export const MobileMenuContext = createContext<MobileMenuContextType>({
  isOpen: false,
  toggle: () => {
  },
  hasOrderButton: false,
  showOrderButton: (value) => {
  }
});

export const MobileMenuProvider: React.FunctionComponent<{
  children: ReactNode,
}> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasOrderButton, setHasOrderButton] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen)
  };
  const showOrderButton = (value: boolean | ((prevState: boolean) => boolean)) => {
    setHasOrderButton(value);
  }

  return (
    <MobileMenuContext.Provider value={{
      isOpen,
      toggle,
      hasOrderButton,
      showOrderButton,
    }}>
      {children}
    </MobileMenuContext.Provider>)
}