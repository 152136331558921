import { motion, Variants } from "framer-motion";
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from "react";
import styled from 'styled-components';

const StyledCard = styled(motion.div)`
  width: 330px;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 20px;
  box-shadow: 0 0 1px hsl(0deg 0% 0% / 0.075), 0 0 2px hsl(0deg 0% 0% / 0.075),
  0 0 4px hsl(0deg 0% 0% / 0.075), 0 0 8px hsl(0deg 0% 0% / 0.075),
  0 0 16px hsl(0deg 0% 0% / 0.075);
`;

const cardVariantsMobile: Variants = {
  offscreen_mobile: {
    x: -400
  },
  offscreen_mobile_reverse: {
    x: 400,
  },
  onscreen_mobile: {
    x: 0,
    rotate: -10,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8
    }
  },
  onscreen_mobile_reverse: {
    x: 0,
    rotate: 10,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8
    }
  }
};

const cardVariantsDesktop: Variants = {
  offscreen: {
    y: -600
  },
  offscreen_reverse: {
    y: 600,
  },
  onscreen: {
    y: 0,
    rotate: -10,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 1.2
    }
  },
  onscreen_reverse: {
    y: 0,
    rotate: 10,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 1.2
    }
  }
}


const Styles = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      if (window.innerWidth < 768) {
        setIsMobile(true);
        console.log("mobile");
      } else {
        setIsMobile(false);
        console.log("desktop");
      }
    }
  }, []);
  return (
    <section className="bg-white py-5 overflow-hidden md:flex md:justify-around md:min-h-[40rem] md:items-center">
      <motion.div
        className="flex flex-col py-12 justify-between items-center"
        // initial={isMobile ? 'offscreen_mobile' : 'offscreen'}
        // whileInView={isMobile ? 'onscreen_mobile' : 'onscreen'}
        // viewport={{ once: true, amount: 0.8 }}
      >
        <div className="text-black text-4xl font-semibold pb-7">Scegli</div>
        <StyledCard variants={isMobile ? cardVariantsMobile : cardVariantsDesktop}>
          <StaticImage src="../images/hair-styles/1.jpg" alt="firstImage" height={200} width={300}/>
        </StyledCard>
      </motion.div>
      <motion.div
        className="flex flex-col py-12 justify-between items-center"
        // initial={isMobile ? 'offscreen_mobile_reverse' : 'offscreen_reverse'}
        // whileInView={isMobile ? 'onscreen_mobile_reverse' : 'onscreen_reverse'}
        // viewport={{ once: true, amount: 0.8 }}
      >
        <div className="text-black text-4xl font-semibold pb-7">il tuo</div>
        <StyledCard variants={isMobile ? cardVariantsMobile : cardVariantsDesktop}>
          <StaticImage src="../images/hair-styles/2.jpg" alt="secondImage" height={200} width={300}/>
        </StyledCard>
      </motion.div>
      <motion.div
        className="flex flex-col py-12 justify-between items-center"
        // initial={isMobile ? 'offscreen_mobile' : 'offscreen'}
        // whileInView={isMobile ? 'onscreen_mobile' : 'onscreen'}
        // viewport={{ once: true, amount: 0.8 }}
      >
        <div className="text-black text-4xl font-semibold pb-7">look!</div>
        <StyledCard variants={isMobile ? cardVariantsMobile : cardVariantsDesktop}>
          <StaticImage src="../images/hair-styles/3.jpg" alt="thirdImage" height={200} width={300}/>
        </StyledCard>
      </motion.div>
    </section>
  );
}


export default Styles;