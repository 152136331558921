import { motion, useTransform, useViewportScroll } from 'framer-motion';
import React, { useEffect, useState } from "react";
import { Link } from 'react-scroll';
import styled from 'styled-components';

const StyledNavigation = styled(motion.div)`
  display: none;

  @media (min-width: 768px) {
    max-width: 1440px;
    display: block;
    width: 100%;
    position: fixed;
    z-index: 10;
    overflow: hidden;
  }
`

const StyledNavigationLink = styled(Link)`
  color: white;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  position: relative;
  font-family: 'Roboto', serif;
  cursor: pointer;

  &:hover {
    color: #991b1b;
    font-weight: bold;

    &:after {
      opacity: 1;
      filter: none;
      transform: translateX(0px);
    }
  }

  &:after {
    position: absolute;
    content: '';
    height: 5px;
    width: 100%;
    background: #991b1b;
    display: block;
    bottom: 0;
    opacity: 0;
    transform: translateX(100px);
    transition: all 0.2s ease-in-out;
  }

  @media (min-width: 1023px) {
    letter-spacing: 5px;
  }
`

const StyledLogoAndLinks = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Navigation = () => {
  const [isScrolling, setIsScrolling] = useState(false);
  const { scrollYProgress } = useViewportScroll();
  const yRange = useTransform(scrollYProgress, [0, 0.9], [0, 100]);

  useEffect(() => yRange.onChange(v => {
    if (v > 0) {
      setIsScrolling(true);
    } else
      setIsScrolling(false);
  }), [yRange]);

  return (
    <StyledNavigation
      animate={isScrolling ? 'scrolling' : 'onTop'}
      variants={{
        onTop: {},
        scrolling: {
          padding: '0',
          transition: {
            duration: .3,
          }
        }
      }}>
      <StyledLogoAndLinks initial="onTop" animate={isScrolling ? 'scrolling' : 'onTop'} variants={{
        onTop: {
          backgroundColor: 'transparent',
          padding: '0rem 1rem 0 1rem',
        },
        scrolling: {
          backgroundColor: 'black',
          padding: '0 0.5rem',
          transition: {
            duration: 1,
            ease: "easeInOut",
          }
        }
      }}>
        <motion.div initial="hidden" animate="visible" variants={{
          hidden: {
            opacity: 0,
            x: -100,
          },
          visible: {
            opacity: 1,
            x: 0,
            transition: {
              delay: 0.5,
              duration: 0.8,
            }
          }
        }}
        >
          <h1 className="pt-2 flex flex-col w-full">
            <span className="text-3xl text-accent font-bold">MOSTA</span>
            <span className="text-xl">SALOON</span>
          </h1>
        </motion.div>
        <motion.div className="flex gap-16" initial="hidden" animate="visible" variants={{
          hidden: {
            opacity: 0,
            x: 100,
          },
          visible: {
            opacity: 1,
            x: 0,
            transition: {
              delay: 0.5,
              duration: 0.8,
            }
          }
        }}>
          {itemIds.map(i => (
            <StyledNavigationLink
              key={i.id}
              className="flex justify-between py-1"
              to={i.scrollTo}
              smooth={true}
              duration={800}>
              {i.name}
            </StyledNavigationLink>
          ))}

        </motion.div>
      </StyledLogoAndLinks>
    </StyledNavigation>
  )
}

const itemIds = [
  { id: 0, name: 'Home', scrollTo: 'hero' },
  { id: 2, name: 'Orario', scrollTo: 'opening-hours' },
  { id: 3, name: 'Prezzi', scrollTo: 'prices' },
  { id: 5, name: 'Media', scrollTo: 'gallery' },
  { id: 4, name: 'Contatti', scrollTo: 'contacts' },
];

export default Navigation;