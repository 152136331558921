import { motion, useTransform, useViewportScroll } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { MobileMenuContext } from '../../context/MobileMenuContext';
import Button from '../Button';
import MobileMenu from './MobileMenu/MobileMenu';

const StyledMotionDivider = styled(motion.div)`
  background: white;
  height: 3px;
`;

const StyledLogoAndHamburger = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledTopBar = styled(motion.div)`
  display: block;
  width: 100%;
  position: fixed;
  z-index: 10;
  overflow: hidden;
  padding: 1rem 1rem 0 1rem;

  @media (min-width: 768px) {
    display: none;
  }
`

function TopBar() {

  const hasOrderButton = useContext(MobileMenuContext).hasOrderButton;
  const [isScrolling, setIsScrolling] = useState(false);
  const { scrollYProgress } = useViewportScroll();
  const yRange = useTransform(scrollYProgress, [0, 0.9], [0, 100]);

  useEffect(() => yRange.onChange(v => {
    if (v > 0) {
      setIsScrolling(true);
    } else
      setIsScrolling(false);
  }), [yRange]);
  return (
    <StyledTopBar animate={isScrolling ? 'scrolling' : 'onTop'} variants={{
      onTop: {},
      scrolling: {
        padding: 0,
        transition: {
          duration: .3,
        }
      }
    }}>
      <StyledMotionDivider className="divider" initial="hidden" animate="visible" variants={{
        hidden: {
          width: 0,
        },
        visible: {
          width: "100%",
          transition: {
            duration: .3,
          }
        }
      }}
      >
      </StyledMotionDivider>
      <StyledLogoAndHamburger initial="onTop" animate={isScrolling ? 'scrolling' : 'onTop'} variants={{
        onTop: {
          backgroundColor: 'transparent',
          padding: 0,
        },
        scrolling: {
          backgroundColor: 'black',
          padding: '0 0.5rem',
          transition: {
            duration: 1,
            ease: "easeInOut",
          }
        }
      }}>
        <motion.div initial="hidden" animate="visible" variants={{
          hidden: {
            opacity: 0,
            x: -100,
          },
          visible: {
            opacity: 1,
            x: 0,
            transition: {
              delay: 0.5,
              duration: 0.8,
            }
          }
        }}
        >
          <h1 className="pt-2 flex flex-col w-full">
            <span className="text-3xl text-accent font-bold">MOSTA</span>
            <span className="text-xl">SALOON</span>
          </h1>
        </motion.div>

        {hasOrderButton && <Button height="30px" width="90px"/>}
        <motion.div initial="hidden" animate="visible" variants={{
          hidden: {
            opacity: 0,
            x: 100,
          },
          visible: {
            opacity: 1,
            x: 0,
            transition: {
              delay: 0.5,
              duration: 0.8,
            }
          }
        }}
        >
          <MobileMenu/>
        </motion.div>
      </StyledLogoAndHamburger>
    </StyledTopBar>
  )
}

export default TopBar;