import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";

const Contacts = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
  }, []);
  return (
    <div id="contacts"
         className="flex flex-col md:gap-9 justify-center items-center min-h-screen bg-white overflow-hidden">
      <h4
        className="text-4xl w-2/3 text-center font-semibold mb-9 uppercase border-b border-black text-black">Dove
        Trovarci</h4>
      <motion.div className="flex flex-col md:flex-row items-center md:justify-around w-full gap-5"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.8 }}
                  variants={{
                    hidden: {
                      opacity: 0,
                    },
                    visible: {
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 1,
                      }
                    }
                  }}>
        <div>
          <h2 className="flex flex-col text-center">
            <span className="text-black">Telefono: </span>
            <a href="tel:+39 389 420 7885" className="text-accent">+39 389 420 7885</a>
          </h2>
          <h2 className="text-center">
            <span className="text-black">Indirizzo: </span>
            <a href="https://goo.gl/maps/y7toohyJaHANAv5o8" target="_blank" className="text-accent flex flex-col">
           <span>
             Via Giovanni Verardo Zeviani, 36
           </span>
              <span>
              37131 Verona VR
            </span>
            </a>
          </h2>
        </div>

        <iframe title="Posizione su Google Maps"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11197.945124593343!2d11.0301102!3d45.4398561!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x16cebd0f7ef4855b!2sMosta%20Saloon!5e0!3m2!1sit!2sit!4v1652372233855!5m2!1sit!2sit"
                width={isMobile ? '350' : '700'} height={isMobile ? '300' : '600'} loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
      </motion.div>
    </div>
  );
}

export default Contacts;