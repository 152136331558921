import { motion } from "framer-motion";
// @ts-ignore
import LocomotiveScroll from 'locomotive-scroll';
import * as React from 'react';
import { useContext } from 'react';
import { Link } from 'react-scroll';
import styled from 'styled-components';
import { MobileMenuContext } from '../../../context/MobileMenuContext';

const StyledLink = styled.div`
  display: block;
  padding: 0.5rem;
  text-decoration: none;
  font-size: 2rem;
  letter-spacing: 1px;
  transform-style: preserve-3d;
  transform: translateZ(-25px);
  transition: transform .25s;
  font-family: 'Roboto', serif;
  font-weight: bold;
  border-radius: 10px;
  width: 100%;
  color: white;
  text-align: start;
`;

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: -50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

const colors = ["#ffffff", "#f6f6f6", "#ececec", "#e1e1e1", "#d0d0d0"];

// @ts-ignore
export const MenuItem = ({ i, name, scrollTo }) => {
  const style = colors[i];
  const toggle = useContext(MobileMenuContext).toggle;


  function handleClick(): void {
    toggle();
  }

  return (
    <motion.div
      variants={variants}
      whileHover={{ backgroundColor: '#991b1b', borderRadius: '10px', scale: 1.02, cursor: 'pointer' }}
      whileTap={{ scale: 0.95 }}
    >
      <Link
        className="flex justify-between py-1"
        onClick={handleClick}
        to={scrollTo}
        offset={name === 'Media' ? -50 : 0}
        smooth={true}
        duration={1000}>
        <StyledLink color={style}>{name}</StyledLink>
        <div className="w-[10px] bg-accent rounded-[10px]"></div>
      </Link>
    </motion.div>
  );
};