import { motion } from "framer-motion";
import * as React from 'react';
import { useContext } from 'react';
import { MobileMenuContext } from '../../../context/MobileMenuContext';
import { MenuItem } from "./MenuItem";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

export const NavigationMobile = () => {
  const isOpen = useContext(MobileMenuContext).isOpen;

  return (
    <motion.div
      variants={variants}
      className="navigation-links"
      initial="closed"
      animate={isOpen ? "open" : "closed"}
    >
      {itemIds.map(i => (
        <MenuItem name={i.name} i={i.id} key={i.id} scrollTo={i.scrollTo}/>
      ))}
    </motion.div>
  )
};

const itemIds = [
  { id: 0, name: 'Home', scrollTo: 'hero' },
  { id: 2, name: 'Orario', scrollTo: 'opening-hours' },
  { id: 3, name: 'Prezzi', scrollTo: 'prices' },
  { id: 5, name: 'Media', scrollTo: 'gallery' },
  { id: 4, name: 'Contatti', scrollTo: 'contacts' },
];