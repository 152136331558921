import { motion } from "framer-motion";
import React from "react";
import styled from 'styled-components';

const StyledDayCard = styled(motion.div)`
  display: flex;
  background-color: white;
  color: black;
  font-weight: 600;
  padding: 0.5rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 1.5rem;
  line-height: 2rem;
  font-family: 'Roboto', sans-serif;
  border-bottom: 4px solid #991b1b;
  border-radius: 5px 5px 0 0;

  @media (min-width: 768px) {
    min-width: 700px;
  }
`

const variants = {
  on: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  off: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

const OpeningHours = () => {
  return (
    <section id="opening-hours" className="opening-hours-wrapper" data-scroll-section>
      <motion.div
        className="p-3 flex flex-col justify-center items-center gap-9"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.8 }}
        variants={{
          hidden: {
            opacity: 0,
          },
          visible: {
            opacity: 1,
            transition: {
              duration: 1,
            }
          }
        }}>

        <h2 className="text-4xl w-2/3 text-center font-semibold mb-9 uppercase border-b border-white">Orario di
          apertura</h2>
        <motion.div
          initial="off"
          whileInView="on"
          viewport={{ once: true, amount: 0.8 }}
          className="w-full h-full flex flex-col justify-center items-center gap-3"
          variants={{
            on: {
              transition: {
                duration: 0.5,
                delay: 0.5,
                staggerChildren: 0.1,
                delayChildren: 0.5
              }
            },
            off: {
              transition: {
                staggerChildren: 0.05,
                staggerDirection: -1
              }
            }
          }}
        >
          {Days.map((d => (
              <StyledDayCard
                key={d.day}
                variants={variants}
              >
                <span className="uppercase">{d.day}: </span>
                <span>{d.hours}</span>
              </StyledDayCard>
            )
          ))}
        </motion.div>
      </motion.div>
    </section>
  );
}
export default OpeningHours

const Days = [
  {
    day: 'Lunedì',
    hours: '10 - 21:00'
  },
  {
    day: 'Martedì',
    hours: '8 - 20:30'
  },
  {
    day: 'Mercoledì',
    hours: '8 - 20:30'
  },
  {
    day: 'Giovedì',
    hours: '8 - 20:30'
  },
  {
    day: 'Venerdì',
    hours: '8 - 20:30'
  },
  {
    day: 'Sabato',
    hours: '8 - 20:30'
  },
  {
    day: 'Domenica',
    hours: 'Chiuso'
  }
]
