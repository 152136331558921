import { motion } from 'framer-motion';
import React from "react";
import styled from 'styled-components';

const StyledPriceCard = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 10px;
  background-color: #fafafa;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  width: 100%;

  @media (min-width: 768px) {
    min-width: 500px;
  }
`;

const variants = {
  show: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  hide: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

const Prices = () => {
  return (
      <div id="prices"
           className="flex flex-col justify-center items-center min-h-screen bg-white overflow-hidden">
        <h2
            className="text-4xl w-2/3 text-center font-semibold mb-9 uppercase border-b border-black text-black">Prezzi</h2>
        <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.8 }}
            className="flex flex-col gap-5"
            variants={{
              visible: {
                transition: {
                  staggerChildren: 0.1,
                  delayChildren: 0.5
                }
              },
              hidden: {
                transition: {
                  staggerChildren: 0.05,
                  staggerDirection: -1
                }
              }
            }}>
          {PricesArray.map((price) => {
            return (
                <StyledPriceCard
                    key={price.title}
                    variants={{
                      hidden: {
                        opacity: 0,
                        x: (price.price.includes('20')) ? -100 : 100,
                      },
                      visible: {
                        opacity: 1,
                        x: 0,
                        transition: {
                          duration: 1,
                        }
                      }
                    }}>
                  <p className="text-center">
                    <span className="text-black">{price.title}</span>
                  </p>
                  <h3 className="text-xl font-bold">
                    <span className="text-black">{price.price}</span>
                    <span className="text-accent"> €</span>
                  </h3>
                </StyledPriceCard>
            );
          })}
        </motion.div>

      </div>
  );
}

const PricesArray: { title: string, price: string }[] = [
  {
    title: 'Taglio + Shampoo + Gel',
    price: '15'
  },
  {
    title: 'Barba + Capelli',
    price: '20'
  },
  {
    title: 'Barba con panno caldo & maschera',
    price: '20'
  },
  {
    title: 'Tinta capelli corti',
    price: 'Da 15'
  },
  {
    title: 'Tinta capelli lunghi',
    price: 'Da 20'
  },
  {
    title: 'Taglio per bambini (5 - 12 anni)',
    price: '14-15'
  }
]


export default Prices;
