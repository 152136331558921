import { motion } from "framer-motion";
import React, { useContext, useRef } from "react";
import { MobileMenuContext } from '../../../context/MobileMenuContext';
import Hamburger from './Hamburger';

const sidebar = {
  open: (height = 1000) => ({
    // clipPath: `circle(${height * 2 + 200}px at 90vw 40px)`,
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
      opacity: { duration: 0.5 }
    }
  }),
  closed: {
    // clipPath: "circle(30px at 90vw 60px)",
    y: -1000,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
      opacity: { duration: 0.5 }
    }
  }
};

function MobileMenu() {
  const isOpen = useContext(MobileMenuContext).isOpen;
  const containerRef = useRef(null);

  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      ref={containerRef}
    >
      <motion.div className="mobile-menu" variants={sidebar}/>
      <Hamburger/>
    </motion.nav>
  );
}

export default MobileMenu;