import { motion } from "framer-motion"
import { StaticImage } from 'gatsby-plugin-image';
import React, { useContext } from "react"
import styled from 'styled-components';
import { MobileMenuContext } from '../context/MobileMenuContext';
import Button from './Button';
import { NavigationMobile } from './TopBar/MobileMenu/NavigationMobile';

const StyledHeroText = styled.div`
  text-transform: uppercase;
  padding: 0.25rem 0.75rem;
  overflow-wrap: break-word;
  font-weight: bold;
  font-size: 3.75rem;
  line-height: 1.375;
  text-align: right;

  @media (min-width: 768px) {
    font-size: 4.5rem;
    text-align: center;
  }

  @media (min-width: 1023px) {
    font-size: 6.5rem;
    text-align: center;
  }

`

const Hero = () => {
  const isOpen = useContext(MobileMenuContext).isOpen;
  const showOrderButton = useContext(MobileMenuContext).showOrderButton;

  if (typeof document !== `undefined`) {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }

  return (
    <section id="hero">
      <div className="hero">
        <div className="flex flex-col pt-[10rem] md:pt-[18rem] justify-between min-h-screen">
          <motion.div initial="hidden" animate="visible" variants={{
            hidden: {
              opacity: 0,
            },
            visible: {
              opacity: 1,
              transition: {
                delay: 0.8,
                duration: 1.5,
              }
            }
          }}>
            <StyledHeroText>Il
              salone che ti
              dà
              stile
            </StyledHeroText>
          </motion.div>
          <motion.div
            className="flex justify-center"
            initial="hidden"
            animate="visible"
            onViewportEnter={() => showOrderButton(false)}
            onViewportLeave={() => showOrderButton(true)}
            variants={{
              hidden: {
                opacity: 0,
                y: -50,
              },
              visible: {
                opacity: 1,
                y: 0,
                transition: {
                  delay: 0.5,
                  duration: 0.8,
                }
              }
            }}
          >
            <Button height="55px" width="150px"/>
          </motion.div>
          <motion.div className="w-full flex justify-center pb-2" initial="hidden" animate="visible" variants={{
            hidden: {
              opacity: 0,
              y: -50,
            },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                delay: 0.5,
                duration: 0.8,
              }
            }
          }}
          >
            <StaticImage src="../images/arrow-bottom.svg" alt="arrow-down" className="animate-bounce"/>
          </motion.div>
        </div>
      </div>
      {isOpen ? <NavigationMobile/> : null}
    </section>
  )
}

export default Hero
