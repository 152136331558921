// @ts-ignore
import LocomotiveScroll from 'locomotive-scroll';
import React from "react";
import styled from 'styled-components';

const StyledButton = styled.button<{ height: string, width: string }>`
  position: relative;
  display: inline-flex;
  width: ${props => props.width};
  height: ${props => props.height};
  margin: 0 15px;
  perspective: 1000px;
  z-index: 2;

  a {
    font-size: 1rem;
    letter-spacing: 1px;
    transform-style: preserve-3d;
    transform: translateZ(-25px);
    transition: transform .25s;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;

    :before, :after {
      position: absolute;
      content: "PRENOTA";
      width: ${props => props.width};
      height: ${props => props.height};
      display: flex;
      align-items: center;
      justify-content: center;
      border: 5px solid #991b1b;
      box-sizing: border-box;
      border-radius: 5px;
    }

    :before {
      color: white;
      background: #991b1b;
      transform: rotateY(0deg) translateZ(25px);
    }

    :after {
      display: ${props => props.height === '30px' ? 'none' : 'flex'};;
      color: white;
      transform: rotateX(90deg) translateZ(25px);
    }

    :hover {
      transform: ${props => props.height === '30px' ? 'none' : 'translateZ(-25px) rotateX(-90deg)'};
    }
  }
`;

function Button(props: { height: string, width: string }) {


  return (
    <StyledButton height={props.height} width={props.width} aria-label="Prenota"><a
      href="https://wa.me/+393894207885" target="_blank"> </a></StyledButton>
  )
}

export default Button;