import { StaticImage } from 'gatsby-plugin-image';
import React from "react";

const Footer = () => {
  return (
    <footer className="flex flex-col p-5 gap-1 justify-center items-center font-[Roboto] text-sm">
      <div>
        Copyright &copy; {new Date().getFullYear()} -{" "} <span>Mosta Saloon</span>
      </div>
      <div className="flex items-center gap-1">
        Made with
        <a className="mb-0.5" href="https://www.gatsbyjs.com/" target="_blank">
          <StaticImage src="../images/icon.png" alt="gatsby-icon" width={20} height={20}/>
        </a> by{" "}
        <a
          className="text-blue-300"
          href="https://cristianturmacu.com/"
          target="_blank">Cristian</a>
      </div>
    </footer>
  )
}

export default Footer;